<template>
	<div class="animated fadeIn">
		<div class="container">
			<change-password-form
				:errors-import="errors.all()"
				@requestChangePassword="requestChangePassword" />
		</div>
	</div>
</template>
<script>

import User from '@/util/User';
import { Validations as messages } from '@/translations';
import ChangePasswordForm from './components/ChangePasswordForm';

export default {
	name: 'ChangePassword',
	messages,
	components: {
		ChangePasswordForm,
	},
	data() {
		return {
			form: new this.$Form({}),
			user: new User(),
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors() {
			try {
				return this.user.errors;
			} catch (error) {
				return {};
			}
		},
		routeId() {
			return this.$route.params.userId || this.$route.params.corporateId || this.$route.params.customerId || this.$user.details().id;
		},
	},
	mounted() {
		let { username } = this.$route.params;
		if (typeof username === 'undefined') {
			username = '';
		}
		this.$parent.titleParam = { user: username };
		this.setFocus('new_password');
	},
	methods: {
		requestChangePassword(payload) {
			this.user.changePassword(this.routeId, payload).then(() => {
				try {
					this.alert.toast('success', this.translate('password_changed'));
				} finally {
					if (this.$route.params.userId) {
						this.$router.push({ name: 'Users' });
					} else if (this.$route.params.corporateId) {
						this.$router.push({ name: 'Corporates' });
					} else if (this.$route.params.customerId) {
						this.$router.push({ name: 'Customers' });
					} else if (this.$user.details().id) {
						this.$router.push({ name: 'Home' });
					}
				}
			}).catch(() => { this.form = new this.$Form(payload); });
		},
	},
};
</script>
